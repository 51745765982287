@import url("https://fonts.googleapis.com/css2?family=Baloo+Thambi+2&display=swap");
:root {
  --color-primary-yellow: #ffc539;
  --color-primary-marineBlue: #2d4059;
  --color-primary-turquoise: #97ccd2;

  --color-secondary-green: #97ff96;
  --color-secondary-red: #ff928e;
  --color-secondary-white: #f6f6f6;

  --color-bg-inactive: #e0e9ea;
  --color-bg-indicator: #dae9eb;

  --body-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  --body-font-size: 18px;
  --body-line-height: 30px;
  --body-letter-spacing: 0.05rem;
  --header-first-font-size: 70px;
  --header-second-font-size: 24px;

  --link-font-weight: bold;

  --mobile-header-second-font-size: 20px;
  --mobile-body-font-size: 16px;
  --mobile-body-font-size-sm: 14px;

  --mb-sm: 0.5rem;
  --mb-md: 1rem;
  --mb-lg: 1.5rem;
  --mb-xl: 2rem;

  --button-font-weight: 500;
  --button-border-radius: 10px;
  --button-box-shadow: 0px 2px 2px var(--color-primary-marineBlue);
  --button-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  --button-secondary-box-shadow: 1px 2px 2px var(--color-secondary-white);
  --button-border: 1px solid #000;
}

.list_item--link {
  text-decoration: none;
  color: var(--color-primary-marineBlue);
  background-color: transparent;
  font-weight: var(--link-font-weight);
  letter-spacing: var(--body-letter-spacing);

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-block: 0.5rem;
  padding-block: 0.625rem;
  padding-inline: 1.875rem;

  &.list {
    border: 1px solid #000;
    border-radius: 10px;
  }

  &:hover,
  &:focus {
    text-decoration: underline;
    color: var(--color-secondary-white);
    background-color: var(--color-primary-marineBlue);
    border-color: var(--color-primary-marineBlue);
    outline-offset: 5px;

    svg path#Icon_awesome-arrow-alt-circle-right {
      fill: var(--color-secondary-white);
    }

    //   &.suggestion {
    //     border-radius: 6px;
    //   }
  }
}

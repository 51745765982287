.page_footer {
  width: 100%;
  height: 4.4rem;
  color: var(--color-secondary-white);
  background-color: var(--color-primary-marineBlue);

  margin-bottom: auto;
  position: absolute;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  a {
    display: flex;
    align-items: center;
    img {
      width: 75px;
      height: auto;
    }

    &:focus {
      background-color: var(--color-bg-indicator);
      outline-color: var(--color-secondary-white);
    }
  }
}

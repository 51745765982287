.help_modal {
  .modal-content_container {
    min-height: 350px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 70%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    background: var(--color-secondary-white);
    border-radius: var(--button-border-radius);

    @media (max-width: 500px) {
      width: 100vw;
      height: 100vh;
      border-radius: 0;
      padding: 1rem;
    }

    .modal_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 1rem;
      margin-bottom: 1rem;

      h2#modal-help {
        margin-top: 0;
      }

      .help-modal-close_button {
        border-radius: var(--button-border-radius);
      }
    }
    .modal-content {
      margin-bottom: 1rem;
      #modal-help-description {
        margin: 0;
        margin-bottom: 1rem;
      }
    }

    .help-modal-close_button {
      &.lg_btn {
        @media (max-width: 500px) {
          width: 100%;
        }
      }

      span {
        margin-right: 0.5rem;
      }
    }

    // .close_icon {
    //   width: var(--body-font-size);
    // }
  }
}
.result_modal {
  div.MuiDialog-paper {
    margin: 1em;
    div.MuiDialogContent-root {
      overflow-y: visible;
      @media only screen and (max-width: 375px) {
        padding: 8px 16px !important;
      }
      p#dialog-description {
        color: #000;
      }
      &.result-list_container {
        ul.result-list {
          display: flex;
          flex-direction: column;
          width: 100%;
          margin: 0;
          margin-bottom: 1em;
          padding: 0;

          li.result-list_item {
            display: flex;
            align-items: center;
            margin-bottom: 2rem;
            justify-content: space-between;
            box-sizing: border-box;
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            background: #fff;
            border-radius: 10px;
            padding: 0.5rem;
            list-style: none;
            box-shadow: 5px 2px 20px 0 rgba(46, 61, 73, 0.5);
            @media only screen and (max-width: 414px) {
              box-shadow: none;
              border-radius: 0;
              border-bottom: 1px solid var(--color-primary-marineBlue);
              padding: 0;
              padding-bottom: 0.5em;
              margin-bottom: 1em;
            }

            .column {
              display: flex;
              flex-direction: column;
              align-items: center;
              padding: 0 1rem;

              @media only screen and (max-width: 414px) {
                padding: 0;
              }
              p {
                margin: 0.2rem 0;
                @media only screen and (max-width: 414px) {
                  align-self: flex-start;
                }
                @media only screen and (max-width: 375px) {
                  align-self: flex-start;
                  font-size: 14px;
                }
              }

              img {
                height: 200px;
                width: 200px;
                @media only screen and (max-width: 587px) {
                  height: 150px;
                  width: 150px;
                }
                @media only screen and (max-width: 375px) {
                  height: 100px;
                  width: 100px;
                }
              }
              &:first-of-type {
                img {
                  border: 2px solid #000;
                  border-radius: 10px;
                }
              }
            }
          }
        }
      }
    }

    .MuiDialogActions-root.button_container {
      justify-content: flex-start !important;
      padding: 8px 24px !important;
      align-items: center !important;

      @media only screen and (max-width: 414px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start !important;
      }

      @media only screen and (max-width: 375px) {
        padding: 8px 16px !important;
      }

      button.MuiButton-root {
        background: #000;
        border-radius: 10px;
        border: 1px solid var(--color-primary-marineBlue);
        color: #fff;
        font-size: 18px;
        letter-spacing: 0.06rem;
        padding: 0.5rem 0.9rem;
        text-transform: none;
        margin: 1rem;
        box-shadow: 2px 2px 2px rgba($color: #000000, $alpha: 0.4);

        @media only screen and (max-width: 414px) {
          margin: 0.5em;
          &:last-of-type {
            margin-left: 0;
          }
        }
        &:first-of-type {
          margin-left: 0;

          @media only screen and (max-width: 414px) {
            margin-top: 0 !important;
          }
        }
      }
    }
  }
}

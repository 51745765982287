.deck--list-item {
  list-style: none;
  @media (max-width: 320px) {
    width: 100%;
    height: 100%;
  }
  // &:nth-child(odd) {
  //   transform: rotate(-3deg);
  // }
  // &:nth-child(even) {
  //   transform: rotate(3deg);
  // }
}

.card {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;

  width: 210px;
  height: 270px;

  background: var(--color-secondary-white);
  color: #000;
  border-radius: 10px;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  // box-shadow: 5px 2px 10px 0 rgba(46, 61, 73, 0.4);

  padding: 0.4rem;

  &:focus {
    outline-color: #fff !important;
  }

  &:not(.person) {
    .card--text {
      padding-top: 2rem;

      @media (max-width: 375px) {
        padding-top: 0.5rem;
      }
    }
  }

  &.person {
    img {
      width: 100%;
      height: 100%;
      min-width: 60%;
      min-height: 60%;
      border-radius: 10px 10px 0 0;
    }
    .card--text {
      margin-top: 0.5rem;
    }
  }

  p {
    font-size: 18px;
    color: #000;
    margin-top: 0.5rem;
    margin-bottom: 0;
  }

  @media (max-width: 1070px) {
    // width: 210px;
    height: 260px;
  }

  @media (max-width: 990px) {
    width: 190px;
    height: 230px;
  }

  @media (max-width: 906px) {
    width: 210px;
    height: 260px;
  }

  @media (max-width: 768px) {
    width: 190px;
    height: 230px;
  }

  @media (max-width: 700px) {
    width: 210px;
    height: 260px;
  }

  @media (max-width: 535px) {
    width: 190px;
    height: 230px;
  }

  @media (max-width: 400px) {
    width: 180px;
    height: 210px;
  }

  @media (max-width: 375px) {
    width: 170px;
    height: 200px;

    p {
      font-size: 14px;
      margin-bottom: 0;
    }
  }

  @media (max-width: 320px) {
    width: 100%;
  }

  &.is-inactive {
    pointer-events: none;

    &.person {
      pointer-events: initial;
    }
  }

  &.is-selected {
    border: 10px solid #000;
    padding: 0;

    img {
      border-radius: 0;
    }

    @media (max-width: 768px) {
      border-width: 8px;
    }

    @media (max-width: 414px) {
      border-width: 9px;
    }

    @media (max-width: 375px) {
      border-width: 6px;
    }

    @media (max-width: 320px) {
      border-width: 4px;
    }
  }

  &.match {
    background: var(--color-secondary-green);
    // &:not(.person) {
    //   img {
    //     background-color: #fff;
    //     padding: 1rem;
    //     border-radius: 10px;
    //   }
    // }
  }
  &.no-match {
    background: var(--color-secondary-red);
    // &:not(.person) {
    //   img {
    //     background-color: #fff;
    //     padding: 1rem;
    //     border-radius: 10px;
    //   }
    // }
  }
}

.search--autosuggest {
  width: 100%;
  height: 2.8rem;
  padding: 0.5rem;
  border: 1px solid #000;
  border-radius: 6px;
}

.react-autosuggest__suggestions-container--open {
  position: absolute;
  width: 100%;
  background-color: #fff;
  z-index: 9999;

  margin-block: var(--mb-sm);
  box-shadow: 0px 3px 3px 3px rgba($color: #000000, $alpha: 0.2);

  border: 1px solid #000;
  border-radius: 6px;

  .react-autosuggest__suggestions-list {
    padding-inline: 0;
    list-style: none;

    .react-autosuggest__suggestion {
      font-weight: var(--link-font-weight);
      letter-spacing: var(--body-letter-spacing);
      color: var(--color-primary-marineBlue);

      padding-block: 0.625rem;
      padding-inline: 1.875rem;

      border-bottom: 1px solid #000;

      &:last-child {
        border-bottom: 0;
      }
    }
  }
}

.react-autosuggest__suggestion--highlighted {
  background-color: var(--color-primary-marineBlue);
  span {
    color: var(--color-secondary-white);
  }
}

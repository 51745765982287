.deck {
  width: 100%;
  padding: 1rem;
  margin: 0 0 3em;
  background: var(--color-primary-marineBlue);
  // box-shadow: 8px 9px 26px 0 rgba(46, 61, 73, 0.5);
  // border-radius: var(--button-border-radius);
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-items: center;
  align-items: stretch;
  gap: 1rem;

  @media (max-width: 1168px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 906px) {
    padding: 1rem;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
  }

  @media (max-width: 700px) {
    padding: 0.7rem;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.7rem;
  }

  @media (max-width: 400px) {
    padding: 0.5rem;
    gap: 0.5rem;
  }

  @media (max-width: 360px) {
    padding: 0.4rem;
    gap: 0.4rem;
  }

  @media (max-width: 320px) {
    grid-template-columns: repeat(2, 9.25rem);
  }
}
.game_view--not_found {
  width: 90vw;
  margin-inline: auto;
}

.header_herospace {
  width: 100vw;
  position: relative;
  padding-block: 2rem;
  display: flex;

  @media (max-width: 500px) {
    margin-top: 0;
  }

  @media (max-width: 414px) {
    padding-block: 1rem;
  }

  .person_svg {
    width: 20vw;
    position: absolute;
    right: 90px;
    bottom: 0;

    @media (max-width: 992px) {
      right: 3em;
    }

    @media (max-width: 778px) {
      width: 25vw;
      right: 1.5em;
    }

    @media (max-width: 414px) {
      display: none;
    }
  }

  .herospace {
    margin-left: auto;
    margin-right: auto;
    width: 60vw;
    color: #fff;
    background: var(--color-primary-marineBlue);
    border-radius: 10px;
    box-shadow: -5px 2px 2px rgba(45, 64, 89, 0.25);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: 500px) {
      width: 80vw;
    }

    .herospace_title {
      font-size: var(--header-first-font-size);
      margin-top: 4rem;
      margin-bottom: 3rem;

      @media (max-width: 991px) {
        font-size: 50px;
      }

      @media (max-width: 500px) {
        font-size: var(--header-second-font-size);
        margin-top: 1em;
        margin-bottom: 1em;
      }
    }
    .herospace_text {
      font-size: 24px;
      margin-top: 0;
      margin-bottom: 2em;

      @media (max-width: 500px) {
        font-size: 18px;
        margin-top: 0;
        margin-bottom: 1em;
      }
    }
  }
}

@import "./scss/utils/css-variables.scss";

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: var(--body-font-family);
  font-size: var(--body-font-size);
  line-height: var(--body-line-height);

  @media (max-width: 414px) {
    font-size: var(--mobile-body-font-size);
  }
  @media (max-width: 320px) {
    font-size: var(--mobile-body-font-size-sm);
  }
}

/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */

button,
input,
optgroup,
select,
textarea {
  font-family: var(--body-font-family) !important; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

button {
  cursor: pointer;
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */

button,
input {
  /* 1 */
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */

button,
select {
  /* 1 */
  text-transform: none;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  border: none;
  padding: 0;
  background: none;
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#root {
  position: relative;
  min-height: 100vh;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.admin-main {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 70px;
}

.admin-content-header {
  display: flex;
  justify-content: space-between;
}

.table {
  width: 100%;
  border-collapse: collapse;

  td,
  th {
    border: 1px solid #ddd;
    padding: 0.5rem;
  }

  th {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    text-align: left;
    background-color: var(--color-primary-marineBlue);
    color: white;
    font-size: 1.5rem;
  }

  tr {
    &:nth-child(even) {
      background-color: #f2f2f2;
    }
    &:hover,
    :focus {
      background-color: #ddd;
    }
  }
}

*:focus {
  outline-style: dashed;
  outline-width: 2px;
  outline-offset: 3px;
  outline-color: #000;
}

*:focus:not(:focus-visible) {
  outline: none;
}

.start-view_main {
  width: 80vw;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 991px) {
    width: 80vw;
  }
}

.action_show-list {
  width: 80vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.avaliable_games--view {
  margin-inline: 1.875rem;

  .avaliable_games--header {
    margin-block-start: 2rem;

    @media screen and (min-width: 740px) {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .search--container {
        position: relative;
        width: 50%;
      }
    }
  }

  .games--list {
    list-style: none;
    padding-inline: 0;
    margin-block-start: 0;
    margin-block-end: 2rem;
  }
}

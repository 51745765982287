section.description_section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;

  .instructions_title {
    margin: 1rem 0;

    @media (max-width: 414px) {
      font-size: 20px;
    }
    @media (max-width: 320px) {
      font-size: 18px;
    }
  }

  & > :last-child {
    width: 50%;
    align-self: center;
  }

  ul.instructions_list {
    padding: 0;
    margin-top: 0;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 1fr;
    grid-column-gap: 1em;

    @media only screen and (max-width: 414px) {
      grid-template-columns: repeat(1, 1fr);
      grid-row-gap: 1em;
    }

    .instructions_list_item {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 1em;
      background: var(--color-bg-indicator);
      border-radius: var(--button-border-radius);
      box-shadow: 2px 2px 5px 0 rgba(46, 61, 73, 0.5);

      @media (max-width: 414px) {
        &:last-of-type {
          margin-bottom: 1em;
        }
      }

      .instruction {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 1rem;

        @media (max-width: 414px) {
          width: 100%;
          margin-bottom: 0;
        }
      }
      .instruction-card_svg {
        width: 15vw;
        align-self: center;

        @media (max-width: 414px) {
          width: 30vw;
          margin-bottom: 1em;
        }
      }
      .instruction-edit_svg {
        width: 5vw;
        align-self: center;

        @media (max-width: 414px) {
          width: 15vw;
          margin-bottom: 1em;
        }
      }
    }
  }

  .active_button {
    margin-block-end: 3rem;
    @media (max-width: 414px) {
      width: 100%;
      margin-block-start: 1em;
      font-size: var(--mobile-header-second-font-size);
    }
  }
}

main.game_view--container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding-left: 2rem;
  padding-right: 2rem;

  @media only screen and (max-width: 320px) {
    padding: 0;
  }

  section.game_section--container {
    width: 100%;
    margin-top: 1rem;

    @media only screen and (max-width: 900px) {
      width: 90vw;
    }

    @media only screen and (max-width: 414px) {
      width: 100vw;
    }

    @media only screen and (max-width: 320px) {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

button.restart_btn {
  background: var(--color-primary-marineBlue) !important;
  border: var(--button-border) !important;
  border-radius: var(--button-border-radius) !important;
  color: var(--color-secondary-white) !important;
  font-size: var(--body-font-size) !important;
  letter-spacing: 0.1rem;
  padding: 0.7rem 0.9rem !important;
  text-transform: none;
  margin-bottom: 1rem;
  line-height: var(--body-line-height) !important;
  box-shadow: var(--button-box-shadow);
  transition: none !important;

  @media (max-width: 414px) {
    margin-inline-start: 0.7rem;
    padding: 0.5rem 0.7rem !important;
  }

  @media (max-width: 360px) {
    margin-inline-start: 0.4rem;
  }

  @media (max-width: 320px) {
    margin-inline-start: 0.4rem;
  }

  &:hover,
  &:focus {
    color: #000 !important;
    background-color: var(--color-bg-indicator) !important;
    border: var(--button-border) !important;
  }

  &:focus {
    outline-offset: 5px !important;
    outline-color: red !important;
  }
}

.active_button {
  height: 78px;
  margin: 1em 0;
  padding: 0.1rem 1rem;
  font-size: var(--header-second-font-size);
  background: var(--color-primary-yellow);
  border-radius: var(--button-border-radius);
  text-align: center;
  box-shadow: var(--button-box-shadow);

  &:hover,
  &:focus {
    color: #000 !important;
    background-color: var(--color-bg-indicator) !important;
    border: var(--button-border) !important;
    box-shadow: none;
  }

  @media (max-width: 499px) {
    width: 100%;
    height: 64px;
    margin-top: 0;
    margin-bottom: 1em;
  }
}

.header-help_button {
  width: fit-content;
  color: var(--color-secondary-white);
  box-shadow: var(--button-secondary-box-shadow);
  border: 1px solid var(--color-bg-inactive);
  border-radius: var(--button-border-radius);
  padding: 0.5rem 0.9rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--body-font-size);
  letter-spacing: 0.1rem;
  line-height: var(--body-line-height);

  @media (max-width: 414px) {
    padding: 0.5rem 0.7rem;
  }
}

.help-modal-close_button {
  color: var(--color-secondary-white);
  font-size: var(--body-font-size);
  background: var(--color-primary-marineBlue);
  box-shadow: var(--button-box-shadow);
  border: 1px solid var(--color-primary-marineBlue);
  border-radius: var(--button-border-radius);
  padding: 0.5rem 0.8rem;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  background-image: url(../../assets/svg/times-solid.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  &:hover,
  &:focus {
    color: #000;
    background-color: var(--color-bg-indicator) !important;
    border: var(--button-border);
    background-image: url(../../assets/svg/times-solid-black.svg);
  }

  &.lg_btn {
    width: 50%;
    // height: 78px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }
  &.sm_btn {
    width: 50px;
    height: 50px;
    // padding: 0.5rem 1rem;
  }
}
.admin-form-submit-btn {
  width: 50%;
}

header {
  width: 100%;
  height: 80px;
  background: var(--color-primary-marineBlue);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 1rem;

  @media (max-width: 767px) {
    padding-inline: 0.5rem;
  }

  .header__startpage-link {
    width: 30%;
    height: auto;
    text-decoration: none;

    display: flex;
    align-items: center;

    .logo_svg {
      width: 100%;
      min-width: 187px;
    }

    &:focus {
      outline-color: #fff !important;
    }

    @media (max-width: 767px) {
      width: 50%;
    }
  }

  .home_link {
    width: fit-content;
    color: #fff;
    display: flex;
    align-items: center;

    &:hover,
    &:focus {
      background-color: var(--color-bg-indicator);
      color: #000;
      padding: 0.5rem;

      .home_link__icon {
        background-image: url(../../assets/svg/house-solid-black.svg);
      }
    }
    &:focus {
      outline-color: #fff !important;
    }

    &__text {
      text-decoration: underline;
      margin-inline-end: 1rem;
      font-size: 1.3rem;

      @media (max-width: 450px) {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        border: 0;
      }
    }

    &__icon {
      width: 40px;
      height: 40px;
      background-image: url(../../assets/svg/house-solid.svg);
      background-repeat: no-repeat;
    }
  }

  .header-help_button {
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      font-size: 1.3rem;
      margin-inline-end: 0.5rem;
    }
    .help_icon {
      width: 40px;
      height: 40px;
      background-image: url(../../assets/svg/question-circle-solid.svg);
      background-repeat: no-repeat;

      @media (max-width: 414px) {
        width: 30px;
        height: 30px;
      }
    }
    &:hover,
    &:focus {
      color: #000;
      background-color: var(--color-bg-indicator);
      box-shadow: none;
      .help_icon {
        background-image: url(../../assets/svg/question-circle-solid-black.svg);
      }
    }
    &:focus {
      outline-color: #fff !important;
    }
  }
}

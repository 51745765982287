main {
  padding-block-end: 4.4rem;
}

.link {
  width: fit-content;
  font-weight: 700;
  color: #000;

  &:hover,
  &:focus {
    background-color: var(--color-bg-indicator);
  }
}

.center {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
